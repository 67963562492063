<div class="swiper-box" wmAnimate="fadeIn" #swiperAnimationIn aos once speed="faster">
    <swiper [config]="config">
        <div class="swiper-slide small-swiper-slide" *ngFor="let item of listadoItems" wmAnimate="pulse" speed="superFaster" aos once>
            <div class="slide-large-image">
                <img src="{{ item.mobile }}" alt="" *ngIf="mobile && item.mobile">
                <img src="{{ item.desktop }}" alt="" *ngIf="mobile && !item.mobile">
                <img src="{{ item.desktop }}" alt="" *ngIf="!mobile">
            </div>
        </div>
    </swiper>
    <div class="swiper-large-image-prev swiper-custom-arrow"></div>
    <div class="swiper-large-image-next swiper-custom-arrow"></div>
    <div class="clearfix"></div>
    <div class="swiper-custom-pagination"></div>
</div>