import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-swiper-image-text',
  templateUrl: './swiper-image-text.component.html',
  styleUrls: ['./swiper-image-text.component.scss'],
})
export class SwiperImageTextComponent implements OnInit {
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1.1,
    centeredSlides: true,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2.1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2.1,
        spaceBetween: 20,
        centeredSlides: false,
      },
    },
    navigation: {
      nextEl: '.swiper-custom-next',
      prevEl: '.swiper-custom-prev',
    },
    pagination: true,
  };
  @Input()
  listadoItems: any[] = [];

  @Input()
  slidesPerView: number = 1.1;

  @Input()
  centeredSlides: boolean = true;

  @Input()
  breakpoints = {
    640: {
      slidesPerView: 2.1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2.1,
      spaceBetween: 20,
      centeredSlides: false,
    },
  };

  @Input() title = '';
  @Input() description = '';
  @Input() mobile = false;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.config.slidesPerView = this.slidesPerView;
    this.config.breakpoints = this.breakpoints;
    this.config.centeredSlides = this.centeredSlides;
    // alert(this.slidesPerView);
  }

  navigate(link:string) {
    if(link.indexOf('booking') > -1) {
      window.open(link, '_blank');
      return;
    }
    if (link != undefined) {
      this.router.navigate(['/pais/' + link]);
    }
  }
}
