<div class="container-body pais-page">
    <app-main-slide [smallHeaderAlways]="true" [pais]=true></app-main-slide>
    <div class="go-back">
        <a href="/#el-viaje">
            <img src="/assets/imgs/go-back.svg" alt="">
        </a>
    </div>
    <div class="full-wide-swiper-container">
        <app-swiper-full-wide [listadoItems]="pais.slides" [mobile]="mobile"></app-swiper-full-wide>
    </div>
    <div id="second-section" class="main-content">
        <div class="section-wrapper">
            <div class="great-title" wmAnimate="fadeIn" speed="faster" aos once>
                {{ pais.name }}
            </div>
            <div class="pais-subtitle" *ngIf="pais.subtitle">
                {{ pais.subtitle }}
            </div>
            <div class="section-text pais-description">
                <p>
                    {{ pais.description }}
                </p>
            </div>
            <div class="ciudades-swiper-container">
                <app-swiper-image-text title="Ciudades" [listadoItems]="pais.ciudades" theme="dark" [mobile]="mobile"></app-swiper-image-text>
            </div>
            <div class="hoteles-swiper-container">
                <app-swiper-image-text [centeredSlides]="false" slidesPerView="2.1" title="Hoteles" [listadoItems]="pais.hoteles" theme="dark" [mobile]="mobile"></app-swiper-image-text>
            </div>
            <div class="transportes-swiper-container" *ngIf="pais.transporte.length > 0">
                <app-swiper-image-text [centeredSlides]="false" slidesPerView="2.1" title="Transportes" [breakpoints]="transporteBreakpoints" [listadoItems]="pais.transporte" theme="dark" [mobile]="mobile"></app-swiper-image-text>
            </div>
        </div>
        <!-- <div class="content-section" wmAnimate="fadeIn" speed="faster" aos once>
            <app-swiper-small title="Qué incluye?" [listadoItems]="beneficiosItems" theme="light"></app-swiper-small>
        </div> -->
    </div>
</div>
<app-contact></app-contact>