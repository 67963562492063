import { Component, OnInit } from '@angular/core';
import { AccordionItem } from '../../interfaces/interfaces';
import { PreguntasService } from 'src/app/services/preguntas.service';

@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.scss']
})
export class PreguntasComponent implements OnInit {

   public preguntasRifas;
   public preguntasGenerales;

  public preguntasAccordion: AccordionItem[] = [
    {
      title: '¿Cuándo es el viaje?',
      content: `El viaje de la generación ha comenzado históricamente sobre finales de febrero.<br>
El motivo es que hay un examen (Médica) que por lo general el período de Feb. es sobre el 20 de ese mes.<br>
A partir de ahí, se planea el viaje de 2 meses de duración. <br>
Quienes hagan todo el viaje, llegarían a Uruguay sobre finales de Abril, para ar luego la prueba del internado sobre mediados de Junio.<br>
Quienes decidan no hacer todo el viaje, retornarán antes.
`,
    },
    {
      title: '¿Viajamos toda la generación junta?',
      content: `En los años anteriores al ser 300 personas aprox por generación, lo que se ha decidido es dividir el grupo en 2 sub-grupos de 150 para lograr un viaje más descontracturado y fluido.<br>
Pero perfectamente se puede pensar en un viaje de toda la generación junta. Es cuestión de discutirlo entre todos más adelante viendo pros y contras.
`,
    },
    {
      title: '¿Cuándo tengo que decidir qué itinerario quiero realizar?',
      content: `La idea es que todos tengan tiempo suficiente para pensar y decidir qué viaje quieren hacer, ya que esta decisión depende de varios factores.<br>
Los destinos a realizar (y por ende la duración que tendrá el viaje de cada uno) se decide recién en Agosto 2024. <br>
Esto es debido a que a esa altura ya sabrán un poco más sobre la cantidad de rifas que vendieron, licencias, tiempos de estudios, etc y puedan tomar la decisión de la mejor manera posible.<br>
Hasta Agosto 2024, además de la venta de la rifa, lo que haremos serán juntadas para charlar de los destinos, el proceso de venta de rifas, e irnos conociendo bien de a poco pero sin frenarnos, ya que vamos a convivir juntos 2 meses de viaje y está lindo y es importante saber con quienes vamos a viajar.
`,
    },
    {
      title: '¿Hasta cuándo tengo tiempo para saldar el pago del viaje?',
      content: `El viaje deberá estar pago en su totalidad 2 meses antes de la salida.<br>
Mediante la venta de rifas, que se espera cubra todo el viaje, esto se cumpliría sin problemas ya que el último sorteo será a fines de Diciembre 2024.<br>
En caso que quede un resto por abonar, deberá ser sobre mediados de enero 2025 como máximo.
`,
    },
    {
      title: '¿Qué formas de recaudar tengo?',
      content: `- Rifas<br>
- After Offices en Cervecería Ramón<br>
- Recomendación a viajes dtyt<br>
- Club “dTyT” a disposición para eventos<br>
- Eventos privados en Lotus (Montevideo Shopping)
`,
    },
    {
      title: '¿Hasta cuándo me puedo dar de baja del viaje?',
      content: `Cualquier persona puede darse de baja en cualquier momento. Lo único que puede llegar a pasar es que dependiendo del momento en que decida darse de baja, ya se haya incurrido en costos que serán lo único que deba abonar esa persona.<br>
En caso que no se haya incurrido en costo alguno y la persona tenga dinero recaudado en la agencia, ese dinero quedará en la agencia y la persona podrá hacer uso del mismo luego.
`,
    },
    {
      title: '¿Puedo llevar un acompañante?',
      content: `Esto es algo que deberían votar en la generación.<br>
Por nuestra parte no hay ningún problema y de hecho en todas las generaciones anteriores ha existido la posibilidad de llevar 1 acompañante (novi@, amig@, herman@).<br>
Consideramos que lo ideal es no tener más de 1 acompañante, ya que si cada uno de ustedes decide llevar 5 amigxs de acompañantes que no son de la FMed, ya deja de ser un viaje de generación y pasa a ser un viaje de grupos de amigos que nada tiene que ver con el viaje de fin de curso.`,
    },
    {
      title: '¿Mi acompañante puede vender rifas?',
      content: `Si.<br>
Los acompañantes tienen los mismos “derechos” que los titulares. Tanto para la venta de rifas, como para la organización, elección de itinerario, precios, etc.
`,
    },
    {
      title:
        '¿Puedo ir a otros destinos previamente o posterior al viaje de la generación?',
      content: `Si claro! y ha pasado varias veces.<br>
Pasa mucho por ej. que pasan por Europa a visitar algún familiar, amigo, etc. O mismo deciden agregar algún destino extra para conocer.<br><br>

Puede pasar 2 situaciones:<br>
1 - Querer comenzar el viaje antes por algún otro destino y luego unirse al grupo de viaje de FMED para comenzar con el itinerario.<br>
2 - Luego de terminar el viaje de la FMED, continuar viajando por otros destinos.<br>
<br>
Cualquiera de las 2 cosas es posible y nosotros nos podemos encargar de organizar todo lo que tiene que ver con los vuelos que necesiten para hacer estas partes “extra”.
`,
    },
  ];



  constructor( private preguntasService: PreguntasService ) { }


  ngOnInit(): void {

    this.preguntasService.getPreguntas().subscribe( (resp:any) => {
      console.log(resp);
      if ( resp.status == 'success' ) {
        this.preguntasAccordion = resp.data.map( item => { return { title: item.preguntasmedicinaPregunta, content: item.preguntasmedicinaRespuesta, categoria: item.preguntasmedicinaCategoria } });
        this.preguntasRifas = this.preguntasAccordion.filter( (el:any) => el.categoria == 'rifa' )
        this.preguntasGenerales = this.preguntasAccordion.filter( (el:any) => el.categoria == 'general' )
      }
    })

  }



}
