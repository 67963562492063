<div id="contacto" class="footer">
    <div class="footer-first">
        <div class="footer-title">
            HABLEMOS DEL VIAJE!
        </div>
        <div class="footer-text">
            LOS INVITAMOS A VENIR EN SUB GRUPOS A VER DETALLES, CONOCERNOS Y ACLARAR DUDAS
        </div>
        <div class="footer-days">
            LUNES A VIERNES DE 11 A 17 HS<br>EN 21 DE SETIEMBRE 2323
        </div>
    </div>
    <div class="footer-second">
        <div class="footer-logo">
            <a href="https://detoqueytoque.com">
                <img src="/assets/imgs/logo-black.svg" alt="">
            </a>
        </div>
        <div class="footer-contacts">
            <div class="contact-item">
                <div class="contact-img">
                    <img src="/assets/imgs/phone-icon.svg" alt="">
                </div>
                <div class="contact-text">
                    <a href="https://wa.me/59892443898" target="_blank">
                        2418 3898 / 092 443 898
                    </a>
                </div>
            </div>
            <div class="contact-item">
                <div class="contact-img">
                    <img src="/assets/imgs/mail-icon.svg" alt="">
                </div>
                <div class="contact-text">
                    <a href="mailto:medicina@detoqueytoque.com">
                        medicina@detoqueytoque.com
                    </a>
                </div>
            </div>
            <div class="contact-item">
                <div class="contact-img">
                    <img src="/assets/imgs/ig-icon.svg" alt="">
                </div>
                <div class="contact-text">
                    <a href="https://www.instagram.com/hacia.asia.med" target="_blank">
                        @hacia.asia.med
                    </a>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>