import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor(private googleService: GoogleAnalyticsService) {}

  pageView(page: string, title: string) {
    console.log('pageView');
    this.googleService.pageView(page, title);
  }

  eventGA(category: string, action: string, label: string) {
    console.log('eventGA');
    this.googleService.event(category, action, label);
  }
}
