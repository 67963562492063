import { Component, OnInit, Input } from '@angular/core';
import { TramoItem } from '../../interfaces/interfaces';

@Component({
  selector: 'app-tramos',
  templateUrl: './tramos.component.html',
  styleUrls: ['./tramos.component.scss']
})
export class TramosComponent implements OnInit {
  @Input() tramos: TramoItem[] = [];
  @Input() nucleo: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleAccordian(event, index, content) {
    if ( content != undefined ) {
      const element = event.target;
      element.classList.toggle('active');
  
      if (this.tramos[index].isActive) {
        this.tramos[index].isActive = false;
      } else {
        this.tramos[index].isActive = true;
      }
      const panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    }

  }
}
