<div class="incluye-list-container" #test wmAnimate="bounceIn" aos once>
    <div *ngFor="let item of listadoServicios; let i = index" class="incluye-item" [class.with-border]="i>0">
        <div class="incluye-item-icon">
            <img src="/assets/imgs/icons/{{item.icon}}.svg">
        </div>
        <div class="incluye-item-text">
            <div class="incluye-item-text-title">
                {{item.title}}
            </div>
            <div class="incluye-item-text-subtitle" *ngIf="item.subtitle">
                {{item.subtitle}}
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>