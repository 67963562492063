import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PaisItem } from 'src/app/interfaces/interfaces';
import { AnimateComponent } from '../../../animate/animate.component';

@Component({
  selector: 'app-swiper-medium',
  templateUrl: './swiper-medium.component.html',
  styleUrls: ['./swiper-medium.component.scss'],
})
export class SwiperMediumComponent {
  @ViewChild('swiperAnimationOut') swiperAnimationOut: AnimateComponent;
  @ViewChild('swiperAnimationIn') swiperAnimationIn: AnimateComponent;

  @Input()
  listadoPaises: PaisItem[] = [];
  public firstClick = false;

  constructor() {}

}
