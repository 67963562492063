import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainSlideComponent } from './main-slide/main-slide.component';
import { StandardSlideComponent } from './standard-slide/standard-slide.component';
import { IncluyeListComponent } from './incluye-list/incluye-list.component';
import { SwiperMediumComponent } from './swipers/swiper-medium/swiper-medium.component';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SwiperSmallComponent } from './swipers/swiper-small/swiper-small.component';
import { AnimateModule } from '../animate/animate.module';
import { TimelineComponent } from './timeline/timeline.component';
import { ChartPieComponent } from './chart-pie/chart-pie.component';
import { SwiperChartComponent } from './swipers/swiper-chart/swiper-chart.component';
import { ChartsModule } from '@rinminase/ng-charts';
import { SwiperLargeImageComponent } from './swipers/swiper-large-image/swiper-large-image.component';
import { SwiperImageTextComponent } from './swipers/swiper-image-text/swiper-image-text.component';
import { TramosComponent } from './tramos/tramos.component';
import { AccordionComponent } from './accordion/accordion.component';
import { SwiperFullWideComponent } from './swipers/swiper-full-wide/swiper-full-wide.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { SwiperImageTextSmallComponent } from './swipers/swiper-image-text-small/swiper-image-text-small.component';
import { RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { RecaptchaModule } from 'angular-google-recaptcha';


@NgModule({
  declarations: [
    MainSlideComponent,
    StandardSlideComponent,
    IncluyeListComponent,
    SwiperMediumComponent,
    SwiperSmallComponent,
    TimelineComponent,
    ChartPieComponent,
    SwiperChartComponent,
    SwiperLargeImageComponent,
    SwiperImageTextComponent,
    TramosComponent,
    AccordionComponent,
    SwiperFullWideComponent,
    YoutubeComponent,
    SwiperImageTextSmallComponent,
    ContactComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    AnimateModule,
    ChartsModule,
    YouTubePlayerModule,
    RouterModule,
    FormsModule, 
    ReactiveFormsModule,
    RecaptchaModule
  ],
  exports: [
    MainSlideComponent,
    StandardSlideComponent,
    IncluyeListComponent,
    SwiperMediumComponent,
    SwiperSmallComponent,
    SwiperChartComponent,
    SwiperLargeImageComponent,
    SwiperImageTextComponent,
    SwiperFullWideComponent,
    SwiperImageTextSmallComponent,
    ChartPieComponent,
    TimelineComponent,
    TramosComponent,
    AccordionComponent,
    YoutubeComponent,
    ContactComponent
  ],
})
export class ComponentsModule {}
