import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'Medicina 2024 | de Toque y Toque';

  public showFloatingFooter = false;

  comstructor() {}

  ngOnInit() {
    
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 150) {
      this.showFloatingFooter = true;
      if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
        this.showFloatingFooter = false;
      } else {
        this.showFloatingFooter = true;
      }
    } else {
      this.showFloatingFooter = false;
    }
  }

  
}
