import { Component, Input } from '@angular/core';
import { StandardItem } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-swiper-small',
  templateUrl: './swiper-small.component.html',
  styleUrls: ['./swiper-small.component.scss'],
})
export class SwiperSmallComponent {
  @Input()
  title = '';
  @Input()
  description = '';
  @Input()
  theme = 'light';
  @Input()
  listadoItems: StandardItem[] = [];

  constructor() {}

}
