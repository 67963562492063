<div *ngFor="let item of tramos; let i = index;" class="tramo-item" [class.main]="item.main" [class.accordion-on]="item.isActive" (click)="toggleAccordian($event, i, item.content)">
    <div class="top-container">
        <div class="tramo-top-text">
            <div class="tramo-name">{{ item.name }}</div>
            <div class="tramo-days">{{ item.days }}</div>
        </div>
        <div class="tramo-countries">
            {{ item.countries }}
        </div>
        <div class="tramo-price">
            {{ item.price }}
        </div>
        <div class="tramo-rifas">
            {{item.rifas}}
        </div>
        <div class="tramo-arrow" *ngIf="item.content">
            <img src="assets/imgs/arrow-down.svg" alt="">
        </div>
        <div class="aux">&nbsp;</div>
        <div class="clearfix"></div>
    </div>
    <div class="panel" hide="!item.isActive" *ngIf="item.content">
        <div [innerHTML]="item.content"></div>
    </div>
</div>


<!-- <div *ngFor="let item of data;let i = index;" class="accordion-container" [class.accordion-on]="item.isActive">
    <button class="accordion" (click)="toggleAccordian($event, i)"> {{item.title}} </button>
    <div class="panel" hide="!item.isActive">
        <div [innerHTML]="item.content"></div>
    </div>
</div> -->