import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreguntasService {

  constructor(private http: HttpClient) { }

  sendPreguntas( pPregunta ) {
    const json = {
      pregunta: pPregunta
    };

    const params =
      'json=' +
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(
      environment.url + '/contact/preguntas',
      params,
      { headers }
    );
  }
  
  getPreguntas() {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.get(
      environment.url + '/contact/get-preguntas',
      { headers }
    );
  }
}
