import { Component, OnInit, Input } from '@angular/core';
import { PaisItem } from '../../interfaces/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { Paises } from '../../paises/paises';
import { switchMap } from 'rxjs/operators';
import { GAService } from '../../services/gaservice.service';

@Component({
  selector: 'app-pais',
  templateUrl: './pais.component.html',
  styleUrls: ['./pais.component.scss'],
})
export class PaisComponent implements OnInit {
  private paises: Paises;
  public pais: PaisItem;
  public innerWidth: any;
  public mobile: boolean = false;

  public transporteBreakpoints = {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    801: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private gaService: GAService
  ) {
    this.paises = new Paises();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 800) {
      this.mobile = true;
    }

    this.route.params.subscribe((params) => {
      const param = params['country'];

      if (param === 'india') {
        this.pais = this.paises.india;
      } else if (param === 'nepal') {
        this.pais = this.paises.nepal;
      } else if (param === 'tailandia') {
        this.pais = this.paises.tailandia;
      } else if (param === 'camboya') {
        this.pais = this.paises.camboya;
      } else if (param === 'vietnam') {
        this.pais = this.paises.vietnam;
      } else if (param === 'indonesia') {
        this.pais = this.paises.indonesia;
      } else if (param === 'filipinas') {
        this.pais = this.paises.filipinas;
      } else if (param === 'alemania') {
        this.pais = this.paises.alemania;
      } else if (param === 'dinamarca') {
        this.pais = this.paises.dinamarca;
      } else if (param === 'noruega') {
        this.pais = this.paises.noruega;
      } else if (param === 'suecia') {
        this.pais = this.paises.suecia;
      } else if (param === 'tanzania') {
        this.pais = this.paises.tanzania;
      } else if (param === 'egipto') {
        this.pais = this.paises.egipto;
      } else {
        this.router.navigate(['/']);
      }

      this.gaService.pageView(`/pais/${param}`, `Pais - ${param}`);
    });


    window.scroll(0, 0);
  }
}
