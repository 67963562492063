import { Component, OnInit } from '@angular/core';
import { IncluyeItem, PaisItem, StandardItem, TramoItem, PremioItem, AccordionItem, ChartItem, SlideItem } from '../../interfaces/interfaces';
import { GAService } from 'src/app/services/gaservice.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public incluyeHomeList: IncluyeItem[] = [
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
  ];

  public incluyeGeneralItems: StandardItem[] = [
    {
      image: '/assets/imgs/incluye/incluye_5.jpg',
      title: '',
      description: 'Vuelos Internacionales (Tasas e impuestos incluidos).',
    },
    {
      image: '/assets/imgs/incluye/incluye_6.jpg',
      title: '',
      description:
        'Hospedajes en <strong>hoteles</strong> con desayuno incluido.',
    },
    {
      image: '/assets/imgs/incluye/incluye_7.jpg',
      title: '',
      description:
        'Todos los transportes (Vuelos internos, buses, trenes, ferrys).',
    },
    {
      image: '/assets/imgs/incluye/incluye_222.jpg',
      title: '',
      description: 'App para el viajero de dTyT',
    },
    {
      image: '/assets/imgs/incluye/incluye_12.jpg',
      title: '',
      description: 'Gestión y costo de visas.',
    },
    {
      image: '/assets/imgs/incluye/incluye_111.jpg',
      title: '',
      description:
        'El seguro de viaje incluído asiste a las/los viajer@s en cualquier situación o momento, incluso casos de COVID. Seguro de viaje válido por un año con cobertura hasta USD 100.000.',
    },
    {
      image: '/assets/imgs/incluye/incluye_1.jpg',
      title: '',
      description:
        '1 persona de dTyT cada 30 viajeros, todos con experiencia en los destinos y en manejo de grupos, brindando soluciones, piques, ideas y estar ahí para lo que se necesite en todo momento.',
    },
    {
      image: '/assets/imgs/incluye/incluye_4.jpg',
      title: '',
      description: '1 médico por grupo que viaje.',
    },
    {
      image: '/assets/imgs/incluye/incluye_3.jpg',
      title: '',
      description:
        'Contactos locales de suma confianza en cada destino, de forma tal de tener una experiencia única y segura en cada lugar.',
    },
    {
      image: '/assets/imgs/incluye/incluye_333.jpg',
      title: '',
      description:
        'Respaldo y experiencia frente a cualquier situación que acontece en el mundo: Pandemia, accidentes naturales, etc. con capacidad de resolución y adaptación.',
    },
    {
      image: '/assets/imgs/incluye/incluye_10.jpg',
      title: '',
      description:
        'Organización en conjunto con la Facultad de Medicina para la realización de 2 actividades académicas opcionales durante el viaje (ver “sobre el viaje oficial de la Facultad de Medicina).',
    },
    /* {
      image: '/assets/imgs/incluye/incluye_2.jpg',
      title: '',
      description:
        'Curso de inglés enfocado a viajes de 3 meses de duración (2 veces por semana). El objetivo del curso es la fluidez y conversación.',
    }, */
  ];

  public recaudarItems: StandardItem[] = [
    {
      image: '/assets/imgs/recaudar/recaudar_1.png',
      title: 'Rifas',
      description: '',
    },
    /* {
      image: '/assets/imgs/recaudar/recaudar_2.jpg',
      title: 'Sorteo al Mundial Qatar 2022',
      description: '',
    }, */
    {
      image: '/assets/imgs/recaudar/recaudar_3.jpg',
      title: 'Recomendanos a tus amigos',
      description: '',
    },
    {
      image: '/assets/imgs/recaudar/recaudar_4.jpg',
      title: 'Huellas dTyT',
      description: '',
    },
    {
      image: '/assets/imgs/recaudar/recaudar_5.jpg',
      title: 'Fiestas en Lotus',
      description: '',
    },
    {
      image: '/assets/imgs/recaudar/recaudar_6.jpg',
      title: 'Espacio Bar dTyT',
      description: '',
    },
  ];

  public pilaresItems: StandardItem[] = [
    {
      image: '/assets/imgs/Pilar-1.jpg',
      title: 'Seguimos armando el viaje juntos',
      link: '/#pilar1',
      description:
        '(2016, <span class="text-tachado">2017</span>, 2018, 2019, 2020, 2021, 2022, 2023, 2024)',
    },
    {
      image: '/assets/imgs/recaudar_1.png',
      title: 'Formas de recaudar',
      link: '/#pilar2',
      description:
        '(Rifas, sorteo mundial, huellas, bailes en Lotus, referencias a dTyT entre otros.)',
    },
    {
      image: '/assets/imgs/Pilar-4.jpg',
      link: '/#pilar3',
      title: 'Descubrí la medicina del mundo',
      description: '',
    },
    {
      image: '/assets/imgs/Pilar-2.jpg',
      link: '/#pilar4',
      title: 'Respaldo y seguridad de dTyT',
      description: '',
    },
  ];

  public paises: any[] = [
    {
      id: 1,
      image: '/assets/imgs/itinerario/itinerario_india.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_india.jpg',
      name: 'India',
      subtitle: '9 días',
      button: true,
      link: 'india',
    },
    {
      id: 2,
      image: '/assets/imgs/itinerario/itinerario_nepal.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_nepal.jpg',
      name: 'Nepal',
      subtitle: '8 días',
      button: true,
      link: 'nepal',
    },
    {
      id: 1,
      image: '/assets/imgs/itinerario/itinerario_tailandia.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_tailandia.jpg',
      name: 'Tailandia',
      subtitle: '10 días',
      button: true,
      link: 'tailandia',
    },
    {
      id: 2,
      image: '/assets/imgs/itinerario/itinerario_camboya.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_camboya.jpg',
      name: 'Camboya',
      subtitle: '4 días',
      button: true,
      link: 'camboya',
    },
    {
      id: 3,
      image: '/assets/imgs/itinerario/itinerario_vietnam.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_vietnam.jpg',
      name: 'Vietnam',
      subtitle: '10 días',
      button: true,
      link: 'vietnam',
    },
    {
      id: 6,
      image: '/assets/imgs/itinerario/itinerario_indonesia.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_indonesia.jpg',
      name: 'Indonesia',
      subtitle: '8 días',
      button: true,
      link: 'indonesia',
    },
    {
      id: 7,
      image: '/assets/imgs/itinerario/itinerario_filipinas.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_filipinas.jpg',
      name: 'Filipinas',
      subtitle: '8 días',
      button: true,
      link: 'filipinas',
    },
  ];

  public paisesExtAsia: any[] = [
    {
      id: 1,
      image: '/assets/imgs/itinerario/itinerario_india.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_india.jpg',
      name: 'India',
      subtitle: '9 días',
      button: true,
      link: 'india',
    },
    {
      id: 2,
      image: '/assets/imgs/itinerario/itinerario_nepal.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_nepal.jpg',
      name: 'Nepal',
      subtitle: '8 días',
      button: true,
      link: 'nepal',
    },
    // {
    //   id: 3,
    //   image: '/assets/imgs/itinerario/itinerario_tailandia.jpg',
    //   imageMobile: '/assets/imgs/itinerario/itinerario_mobile_tailandia.jpg',
    //   name: 'Tailandia',
    //   subtitle: '10 días',
    //   button: true,
    //   link: 'tailandia',
    // },
    // {
    //   id: 4,
    //   image: '/assets/imgs/itinerario/itinerario_camboya.jpg',
    //   imageMobile: '/assets/imgs/itinerario/itinerario_mobile_camboya.jpg',
    //   name: 'Camboya',
    //   subtitle: '4 días',
    //   button: true,
    //   link: 'camboya',
    // },
    // {
    //   id: 5,
    //   image: '/assets/imgs/itinerario/itinerario_vietnam.jpg',
    //   imageMobile: '/assets/imgs/itinerario/itinerario_mobile_vietnam.jpg',
    //   name: 'Vietnam',
    //   subtitle: '10 días',
    //   button: true,
    //   link: 'vietnam',
    // },
    {
      id: 3,
      image: '/assets/imgs/itinerario/itinerario_indonesia.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_indonesia.jpg',
      name: 'Indonesia',
      subtitle: '8 días',
      button: true,
      link: 'indonesia',
    },
    {
      id: 4,
      image: '/assets/imgs/itinerario/itinerario_filipinas.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_filipinas.jpg',
      name: 'Filipinas',
      subtitle: '8 días',
      button: true,
      link: 'filipinas',
    },
  ];

  public paisesEuropa: any[] = [
    {
      id: 1,
      image: '/assets/imgs/itinerario/itinerario_dinamarca.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_dinamarca.jpg',
      name: 'Dinamarca',
      subtitle: '3 días',
      button: true,
      link: 'dinamarca',
    },
    {
      id: 2,
      image: '/assets/imgs/itinerario/itinerario_noruega.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_noruega.jpg',
      name: 'Noruega',
      subtitle: '4 días',
      button: true,
      link: 'noruega',
    },
    {
      id: 3,
      image: '/assets/imgs/itinerario/itinerario_suecia.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_suecia.jpg',
      name: 'Suecia',
      subtitle: '3 días',
      button: true,
      link: 'suecia',
    },
    {
      id: 4,
      image: '/assets/imgs/itinerario/itinerario_alemania.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_alemania.jpg',
      name: 'Alemania',
      subtitle: '5 días',
      button: true,
      link: 'alemania',
    },
  ];

  public paisesAfrica: any[] = [
    {
      id: 1,
      image: '/assets/imgs/itinerario/itinerario_tanzania.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_tanzania.jpg',
      name: 'Tanzania',
      subtitle: '12 días',
      button: true,
      link: 'tanzania',
    },
    {
      id: 2,
      image: '/assets/imgs/itinerario/itinerario_egipto.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_egipto.jpg',
      name: 'Egipto',
      subtitle: '10 días',
      button: true,
      link: 'egipto',
    },
  ];
  public otrosDestinos: any[] = [
    {
      id: 1,
      image: '/assets/imgs/itinerario/otros_destinos_malasia.png',
      imageMobile: '/assets/imgs/itinerario/otros_mobile_malasia.png',
      name: 'Malasia y Singapur',
      subtitle: '6 días',
      button: false,
      // link: 'india',
    },
    {
      id: 1,
      image: '/assets/imgs/itinerario/otros_turquia.jpg',
      imageMobile: '/assets/imgs/itinerario/otros_mobile_turquia.png',
      name: 'Turquía',
      subtitle: '7 días',
      button: false,
      // link: 'india',
    },
    {
      id: 1,
      image: '/assets/imgs/itinerario/otros_turquia.jpg',
      imageMobile: '/assets/imgs/itinerario/otros_mobile_turquia.png',
      name: 'Dubai',
      subtitle: '5 días',
      button: false,
      // link: 'india',
    },
  ];

  public beneficiosItems: StandardItem[] = [
    {
      image: '/assets/imgs/beneficios/beneficios_itau.jpg',
      title: 'Tarjeta Internacional Visa Itaú Gratis por 1 año',
      description:
        'Una tarjeta que es muy usada en el mundo y que es un gran aporte para el viaje. Y lo mejor, no tiene recargo por las ventas en el exterior!',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_ingles.jpg',
      title: 'Clases de Inglés',
      description:
        'Al anotarte al viaje, tendrás la posibilidad de participar de clases de inglés a un costo super preferencial. El objetivo de esto es tener herramientas para desenvolverse allá de mejor manera… hemos visto que con un poco de inglés, la gente disfruta un poco más el viaje… de compartir una charla con gente local, para regatear, para sortear situaciones de viaje… por eso pensamos en estas clases 100% enfocadas a situaciones de viaje. El curso es de 3 meses, 2 veces por semana, divididos en 2 niveles: principiante y básico. Llegado el momento les preguntaremos en qué nivel quisieran anotarse.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_dhl.jpg',
      title: 'DHL',
      description:
        'Con DHL venimos trabajando juntos de forma de poder brindar a quienes viajen un servicio fácil y económico para enviar encomiendas mientras viajan. DHL nos lleva al hotel las cajas con las medidas necesarias para hacer los envíos. Las llenamos de cosas para enviar a Uruguay, se paga el envío online y luego DHL levanta las cajas por el hotel para hacerlas llegar a Uruguay. Tenemos acordada una tarifa especial para los grupos de dTyT; 5kg - 60 usd el envío que llega en 10 días a Uruguay.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_trota.jpg',
      title: '“Trotamundos: la tienda del viajero”',
      description:
        'Una tienda de accesorios pensada para viajerxs a disposición para nosotros y a los grupos. Tenemos descuento en todos sus accesorios entre 15% y 50% dependiendo del accesorio. Ubicada en: Bulevar España 2085 esq Maldonado.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_appviaje.jpg',
      title: 'APP de viaje',
      description:
        'Esta APP fue hecha hace unos años. Reúne toda la información del viaje. Estado de los vuelos que vamos a tomar en tiempo real, el itinerario día a día, las actividades y paseos que hay para hacer en cada ciudad, datos curiosos de cada lugar, y listados para sumarse a las actividades que organizamos ahí mismo. Está en versión Android e IOs. Nos ha resultado muy práctica y una herramienta de mucha utilidad tanto para los usuarios como para nosotros al momento de organizar bien cada paso. La pueden probar en estos días. Se descarga por el nombre “de Toque y Toque” e ingresan con el usuario: med2024@dtyt.com y contraseña: med2024',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_buceo.jpg',
      title: 'Curso de Buceo',
      description:
        'Hacer Buceo es una de las actividades recomendadas durante el viaje, aunque sea probar una vez. Phi Phi, Bali, Gili, El Nido… son de los lugares preferidos por los buceadores, y justo por allí andaremos. Por eso hicimos un convenio con una escuela de buceo en Tailandia y con un instructor en Uruguay para hacer el curso en conjunto. Se hace el curso teórico y la práctica en la piscina en Uruguay, y se termina validando el curso práctico en las aguas de Tailandia, aprovechando el tiempo, la practicidad, el paisaje y el precio ya que nos estamos ahorrando de pagar el Buceo allá.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_fotografia.jpg',
      title: 'Curso de fotografía enfocado a viajes',
      description:
        'Virginia Londero, fotógrafa profesional, brinda un curso de fotografía de 3 meses enfocada 100% a viajes a un precio especial para los grupos de viaje. ideal para que aprovechemos a dejar los mejores recuerdos en nuestras cámaras.',
    },
  ];

  public chartItems: ChartItem[] = [
    {
      title: 'Propuesta de viaje',
      description:
        '¿Consideras atractiva la propuesta? O hubieses preferido que sea más estructurado y guiado?',
      chartLabels: ['Si', 'No'],
      chartData: ['97', '3'],
    },
    {
      title: 'Trato personal / profesional',
      description: '¿Cómo fue el trato hacia ti?',
      chartLabels: ['Muy bueno', 'Bueno'],
      chartData: ['83.2', '15.9'],
    },
    {
      title: 'Ubicación de Hoteles',
      description: '¿Cuál es tu opinión sobre la ubicación de cada hospedaje?',
      chartLabels: ['Muy buena', 'Buena'],
      chartData: ['92.8', '7.2'],
    },
    {
      title: 'Resultado personal',
      description:
        '¿Consideras al viaje como parte de un crecimiento personal?',
      chartLabels: ['Si', 'Otros'],
      chartData: ['97.4', '2.6'],
    },
    {
      title: 'Calificación general',
      description: '¿Cómo calificarías al viaje?',
      chartLabels: ['Muy bueno', 'Bueno'],
      chartData: ['93', '7'],
    },
    {
      title: 'Cumplimiento de servicio',
      description:
        'En base a lo que se habló, propuso y explicó en las reuniones y juntadas previas respecto a lo que iba a ser el viaje, ¿considerás que esas cosas se cumplieron?',
      chartLabels: ['Si', 'Otras'],
      chartData: ['93.9', '6.1'],
    },
  ];

  public groupsImages: SlideItem[] = [
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_1.jpg',
      mobile: '/assets/imgs/groups/fmed_book_1.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_2.jpg',
      mobile: '/assets/imgs/groups/fmed_book_2.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_3.jpg',
      mobile: '/assets/imgs/groups/fmed_book_3.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_4.jpg',
      mobile: '/assets/imgs/groups/fmed_book_4.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_5.jpg',
      mobile: '/assets/imgs/groups/fmed_book_5.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_6.jpg',
      mobile: '/assets/imgs/groups/fmed_book_6.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_7.jpg',
      mobile: '/assets/imgs/groups/fmed_book_7.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_8.jpg',
      mobile: '/assets/imgs/groups/fmed_book_8.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_9.jpg',
      mobile: '/assets/imgs/groups/fmed_book_9.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_10.jpg',
      mobile: '/assets/imgs/groups/fmed_book_10.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_11.jpg',
      mobile: '/assets/imgs/groups/fmed_book_11.png',
    },
  ];

  public academicoImages: SlideItem[] = [
    {
      desktop: '/assets/imgs/academico/academico_desktop_1.png',
      mobile: '/assets/imgs/academico/academico_mobile_1.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_2.png',
      mobile: '/assets/imgs/academico/academico_mobile_2.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_3.png',
      mobile: '/assets/imgs/academico/academico_mobile_3.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_4.png',
      mobile: '/assets/imgs/academico/academico_mobile_4.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_5.png',
      mobile: '/assets/imgs/academico/academico_mobile_5.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_6.png',
      mobile: '/assets/imgs/academico/academico_mobile_6.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_7.png',
      mobile: '/assets/imgs/academico/academico_mobile_7.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_8.png',
      mobile: '/assets/imgs/academico/academico_mobile_8.png',
    },
  ];

  public tramosAsia: TramoItem[] = [
    {
      price: 'USD XXX',
      countries: 'India - Nepal',
      days: '17 días',
      rifas: 'XX, X rifas',
      name: 'Extensión A',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"<br>Además para quienes realicen estos destinos se incluye:</strong><br><br>
      - Entrada y traslado al Taj Mahal y Fuerte Rojo.<br>
- Trekking de 2 días / 1 noche en Dampus (Nepal), incluyendo guías, comidas y alojamiento en la montaña.<br>
- Día de recorrida de templos en Tuc Tuc con entradas incluidas a: <br>
Indian Gate |
Gandhi smritii |
Tumba de Humayun |
Deer Park |
Templo de Bangla Sahib.
<br>

- Día de recorrida de Delhi en Metro con entradas incluidas a: <br>
Old Delhi |
Qutub Minar |
Jama Masjid |
Todos los tickets de Metro necesarios.

`,
    },
    {
      price: 'USD XXX',
      countries: 'Tailandia - Camboya - Vietnam',
      days: '27 días',
      rifas: 'XX, X rifas',
      name: 'Núcleo',
      main: true,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"<br>Además se incluye:</strong><br><br>
      - Palacio Real de Bangkok y Wat Pho <br>
      - Entrada y traslado a los templos de Angkor Wat y guía en Español.<br>
- Entrada y traslado a los Túneles de Cuchi.Guía en Español.<br>
- Crucero de 2 días / 1 noche en Halong Bay. Comidas incluídas. <br>
- Mausoleo, museo y casa de Ho Chi Minh
`,
    },
    {
      price: 'USD XXX',
      countries: 'Indonesia',
      days: '8 días',
      rifas: 'XX, X rifas',
      name: 'Extensión B',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"</strong><br><br>`,
    },
    {
      price: 'USD XXX',
      countries: 'Filipinas',
      days: '8 días',
      rifas: 'XX, X rifas',
      name: 'Extensión C',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"</strong><br><br>`,
    },
  ];

  public tramosNucleo: TramoItem[] = [
    {
      price: 'USD XXX',
      countries: 'Tailandia - Camboya - Vietnam',
      days: '27 días',
      rifas: 'XX, X rifas',
      name: 'Núcleo',
      main: true,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"<br>Además se incluye:</strong><br><br>
      - Entrada y traslado a los templos de Angkor Wat y guía en Español.<br>
- Entrada y traslado a los Túneles de Cuchi.Guía en Español.<br>
- Crucero de 2 días / 1 noche en Halong Bay. Comidas incluídas.
`,
    },
  ];

  public tramosEuropa: TramoItem[] = [
    {
      price: 'USD 1490',
      countries: 'Dinamarca - Noruega - Suecia',
      days: '10 días',
      rifas: '18,6 rifas',
      name: 'Extensión A',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"<br>Además para quienes realicen estos destinos se incluye:</strong><br><br>
      - Vuelo Ho Chi Minh - Copenhague<br>
      - 3 días en Copenhague<br>
      - Tren Copenhague - Oslo<br>
      - 3 días en Oslo<br>
      - Bus Oslo - Bergen<br>
      - 1 día en Bergen<br>
      - Bus Bergen - Estocolmo<br>
      - 3 días en Estocolmo<br>
      - Vuelo Estocolmo - Montevideo
    `,
    },
    {
      price: 'USD 495',
      countries: 'Alemania',
      days: '5 días',
      rifas: '6,2 rifas',
      name: 'Extensión B',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"<br>Además para quienes realicen estos destinos se incluye:</strong><br><br>
      - Vuelo Estocolmo - Hamburgo<br>
      - 2 días en Hamburgo<br>
      - Tren Hamburgo - Berlín<br>
      - 3 días en Berlín<br>
      - Vuelo Berlín - Montevideo`,
    },
  ];

  public tramosAfrica: TramoItem[] = [
    {
      price: 'USD XXX',
      countries: 'Tanzania',
      days: '12 días',
      rifas: 'XX, X rifas',
      name: 'Extensión A',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"<br>Además para quienes realicen estos destinos se incluye:</strong><br><br>
      - Vuelo Ho Chi Minh - Dar es Salam<br>
      - 1 noche en Dar es Salam<br>
      - Ferry Dar es Salam - Zanzibar<br>
      - 6 días en Zanzíbar<br>
      - 1 noche en Moshi<br>
      - 2 días de Safari - 1 noche<br>
      - Safari en Parque Taranguire<br>
      - Safari en Cráter Ngorongoro<br>
      - Vuelo Mochi - Dar es Salam<br>
      - 1 noche en Dar es Salam
`,
    },
    {
      price: 'USD XXX',
      countries: 'Egipto',
      days: '10 días',
      rifas: 'XX, X rifas',
      name: 'Extensión B',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "Qué incluye?"<br>Además para quienes realicen estos destinos se incluye:</strong><br><br>\
      - Vuelo Dar es Salam - El Cairo<br>
      - 4 días en El Cairo<br>
      - 1 día en Giza<br>
      - Tren El Cairo - Luxor<br>
      - 3 días en Luxor<br>
      - 1 día en El Cairo<br>
      - Vuelo El Cairo - Montevideo
      `,
    },
  ];

  public premios: PremioItem[] = [
    {
      name: '2 viajes a las Maldivas all inclusive',
      image: '/assets/imgs/premios/premios_11.png',

    },
    {
      name: '6 Autos 0 Km',
      image: '/assets/imgs/premios/premios_1.jpg',
      // subtitle: '3 autos por mes + 1 extra',
    },
    {
      name: '20 computadoras Mac',
      image: '/assets/imgs/premios/premios_10.jpg',
      // subtitle: '2 viajes por mes',
    },
    {
      name: '10 Viajes a Egipto',
      image: '/assets/imgs/premios/premios_9.jpg',
      // subtitle: '1 viaje por mes',
    },
    {
      name: '10 Viajes a Madrid',
      image: '/assets/imgs/premios/premios_2.jpg',
      // subtitle: '1 viaje por mes',
    },
    // {
    //   name: '5 Viajes dobles a Nueva York',
    //   image: '/assets/imgs/premios/premios_3.jpg',
    //   // subtitle: '1 viaje por mes',
    // },
    {
      name: '10 Viajes a Miami',
      image: '/assets/imgs/premios/premios_4.jpg',
      // subtitle: '1 viaje por mes',
    },
    // {
    //   name: '5 Viajes dobles a Lima',
    //   image: '/assets/imgs/premios/premios_5.jpg',
    //   // subtitle: '2 viajes por mes',
    // },
    {
      name: '20 Viajes a Rio de Janeiro',
      image: '/assets/imgs/premios/premios_6.jpg',
      // subtitle: '2 viajes por mes',
    },
    // {
    //   name: '5 Viajes dobles a Santiago de Chile',
    //   image: '/assets/imgs/premios/premios_7.jpg',
    //   // subtitle: '2 viajes por mes',
    // },
    // {
    //   name: '5 Viajes dobles a Buenos Aires',
    //   image: '/assets/imgs/premios/premios_8.jpg',
    //   // subtitle: '2 viajes por mes',
    // },
    {
      name: '4 combos de Audio y Video última generación',
      image: '/assets/imgs/premios/premios_12.jpg',
      // subtitle: '2 viajes por mes',
    },
  ];

  public rifasAccordion: AccordionItem[] = [
    {
      title: '¿Cómo es el proceso para la gestión de la rifa?',
      content: `Para la realización de una rifa se necesitan básicamente 2 cosas:<br>
1 - La autorización oficial de la FMED. <br>
Esto es un trámite que ya hemos realizado en años anteriores y que lo que hace es dar un “fin” a la rifa, ya que la rifa, se habilita para determinados fines.
<br><br>
2 - Saber la cantidad de rifas que cada uno va a querer.<br>
Esto es importante, ya que los premios que va a tener la rifa, depende proporcionalmente de la cantidad de rifas total que se pida. (ver la pregunta “¿Cómo se definen los premios?”).
<br><br>
Una vez con estas 2 cosas, se presenta en la Dirección Nacional de Loterías y Quinielas la solicitud de la rifa.<br>
A partir de ahí, se estiman unos 3 meses aprox. hasta que finalmente quede aprobada.<br>
Mientras se aprueba, se entregará a los estudiantes lo que se conoce como  “boletos de reserva” para empezar a promocionar la rifa de forma rápida.<br>
Una vez que la rifa queda aprobada por el Ministerio de Economía y Finanzas, se entrega a los estudiantes las rifas propiamente dichas para que entreguen a sus compradores.
`,
    },
    {
      title: '¿Cómo han sido los tiempos en años anteriores?',
      content: `En los años anteriores con los que hemos viajado con la Facultad, los tiempos han sido prácticamente los mismos.<br>
Para este año, si tomáramos como referencia los tiempos de años anteriores, los tiempos serían los siguientes (aprox).<br><br>

1 - En Agosto/Setiembre 2023 (ahora) se decide cuántas rifas va a querer vender cada uno y en paralelo se comienza con el trámite para la autorización en la FMed. También se realiza la entrega de boletos de reserva para que comiencen con la propaganda de la rifa..<br><br>

2 - En Noviembre 2023 se solicita la rifa en Loterías y Quinielas.<br><br>

3 - En Febrero / Marzo 2024 (1 año antes del viaje)  se aprueba la rifa y ya se comienza con la venta propiamente dicha. Tener en cuenta que, una vez que se aprueba ahí lo único que hay que hacer es cobrar la rifa que ya tenían “vendida” con los boletos de reserva.<br><br>

4 -  En Agosto 2024 (5 meses después de la autorización) comienzan los sorteos que finalizarán en diciembre 2024, 2 meses antes de irnos de viaje.<br><br>
`,
    },
    {
      title: '¿Cuánto cuesta cada rifa y cuánto recaudo por cada rifa vendida?',
      content: `La rifa consta de 5 sorteos.<br>
Cada sorteo cuesta 20 usd, por lo que la rifa entera costará 100 usd.<br>
Al ser 5 sorteos distintos, la rifa tiene la posibilidad de venderse fraccionada a distintas personas, no teniendo que si o si un comprador tenga que pagar 100 usd. Es decir, se puede vender un sorteo a una persona y otro sorteo a otra.<br>
Obviamente que lo mejor es vender la rifa entera a un comprador sólo. Para esto se incentiva con un premio extra a quien compre toda la rifa entera.
<br><br>
Por cada Rifa, se recauda 80 usd y 20 usd son para el pago de los premios.
`,
    },
    {
      title: '¿Cómo se puede pagar la rifa?',
      content: `La rifa se puede pagar tanto al contado como con tarjeta con visa hasta 10 cuotas sin recargo y con Oca y Master hasta 12 cuotas sin recargo.<br>
Para el pago contado, cada estudiante tendrá una cuenta en Itau para realizar los depósitos.<br>
Para los pagos con tarjeta, se realizarán via web, de forma que sea simple y no tener que ir hasta donde se encuentre el comprador. Se realiza todo mediantes links y un sistema de gestión que controlará todas las ventas.<br>`,
    },
    {
      title:
        '¿Qué pasa si vendo más rifas de las que necesito para cubrir el viaje?',
      content: `Supongamos que el viaje cueste 4000 usd. Si quisiera pagarlo todo en rifas sería 50 rifas.<br>
Si un estudiante vende 60 rifas, recaudará 4800 usd. Esos 800 usd se darán en efectivo para que pueda utilizarlo durante el viaje.
`,
    },
    {
      title:
        '¿Qué pasa si vendo menos rifas de las que necesito para cubrir el viaje?',
      content: `En caso que con la venta de rifas no llegue a cubrir los costos del viaje (o decidas no vender rifas), la diferencia puede pagarse hasta en 6 cuotas sin recargo con Visa, Oca y Master.<br>
También está la posibilidad de hacer depósitos parciales en la cuenta de cada uno para ir ahorrando durante el año y medio que dura todo proceso, hasta llegar a la fecha del viaje.
`,
    },
    {
      title: '¿Cómo se definen los premios?',
      content: `Los premios que detallamos arriba son los premios que han estado en la rifa de la generación anterior.<br>Los premios podemos definirlos luego en conjunto si gustan.<br>Lo importante es que hay que cumplir con la ley de gastar más del 20% del total de la emisión, que en general ronda los 400.000 usd destinado a premios (suponiendo que se piden un total de 20.000 rifas).`,
    },
  ];

  public preguntasAccordion: AccordionItem[] = [
    {
      title: '¿Cuándo es el viaje?',
      content: `El viaje de la generación ha comenzado históricamente sobre finales de febrero.<br>
El motivo es que hay un examen (Médica) que por lo general en el período de Febrero es sobre el 20 de ese mes.<br>
A partir de ahí, se planea el viaje de 2 meses de duración. <br>
Quienes hagan todo el viaje, llegarían a Uruguay sobre finales de Abril, para dar luego la prueba del internado sobre mediados de Junio.<br>
Quienes decidan no hacer todo el viaje, retornarán antes.
`,
    },
    {
      title: '¿Viajamos toda la generación junta?',
      content: `En los años anteriores al ser 300 personas aprox por generación, lo que se ha decidido es dividir el grupo en 2 sub-grupos de 150 para lograr un viaje más descontracturado y fluido.<br>
Pero perfectamente se puede pensar en un viaje de toda la generación junta. Es cuestión de discutirlo entre todos más adelante viendo pros y contras.
`,
    },
    {
      title: '¿Cuándo tengo que decidir qué itinerario quiero realizar?',
      content: `La idea es que todos tengan tiempo suficiente para pensar y decidir qué viaje quieren hacer, ya que esta decisión depende de varios factores.<br>
Los destinos a realizar (y por ende la duración que tendrá el viaje de cada uno) se decide recién en Agosto 2023. <br>
Esto es debido a que a esa altura ya sabrán un poco más sobre la cantidad de rifas que vendieron, licencias, tiempos de estudios, etc y puedan tomar la decisión de la mejor manera posible.<br>
Hasta Agosto 2023, además de la venta de la rifa, lo que haremos serán juntadas para charlar de los destinos, el proceso de venta de rifas, e irnos conociendo bien de a poco pero sin frenarnos, ya que vamos a convivir juntos 2 meses de viaje y está lindo y es importante saber con quienes vamos a viajar.
`,
    },
    {
      title: '¿Hasta cuándo tengo tiempo para saldar el pago del viaje?',
      content: `El viaje deberá estar pago en su totalidad 2 meses antes de la salida.<br>
Mediante la venta de rifas, que se espera cubra todo el viaje, esto se cumpliría sin problemas ya que el último sorteo será a fines de Diciembre 2023.<br>
En caso que quede un resto por abonar, deberá ser sobre mediados de enero 2024 como máximo.
`,
    },
    {
      title: '¿Hasta cuándo me puedo dar de baja del viaje?',
      content: `Cualquier persona puede darse de baja en cualquier momento. Lo único que puede llegar a pasar es que dependiendo del momento en que decida darse de baja, ya se haya incurrido en costos que serán lo único que deba abonar esa persona.<br>
En caso que no se haya incurrido en costo alguno y la persona tenga dinero recaudado en la agencia, ese dinero quedará en la agencia y la persona podrá hacer uso del mismo luego.
`,
    },
    {
      title: '¿Puedo llevar un acompañante?',
      content: `Esto es algo que deberían votar en la generación.<br>
Por nuestra parte no hay ningún problema y de hecho en todas las generaciones anteriores ha existido la posibilidad de llevar 1 acompañante (novi@, amig@, herman@).<br>
Consideramos que lo ideal es no tener más de 1 acompañante, ya que si cada uno de ustedes decide llevar 5 amigxs de acompañantes que no son de la FMed, ya deja de ser un viaje de generación y pasa a ser un viaje de grupos de amigos que nada tiene que ver con el viaje de fin de curso.`,
    },
    {
      title: '¿Mi acompañante puede vender rifas?',
      content: `Si.<br>
Los acompañantes tienen los mismos “derechos” que los titulares. Tanto para la venta de rifas, como para la organización, elección de itinerario, precios, etc.
`,
    },
    {
      title:
        '¿Puedo ir a otros destinos previamente o posterior al viaje de la generación?',
      content: `Si claro! y ha pasado varias veces.<br>
Pasa mucho por ej. que pasan por Europa a visitar algún familiar, amigo, etc. O mismo deciden agregar algún destino extra para conocer.<br><br>

Puede pasar 2 situaciones:<br>
1 - Querer comenzar el viaje antes por algún otro destino y luego unirse al grupo de viaje de FMED para comenzar con el itinerario.<br>
2 - Luego de terminar el viaje de la FMED, continuar viajando por otros destinos.<br>
<br>
Cualquiera de las 2 cosas es posible y nosotros nos podemos encargar de organizar todo lo que tiene que ver con los vuelos que necesiten para hacer estas partes “extra”.
`,
    },
  ];

  public sugerenciasAccordion: AccordionItem[] = [
    {
      title: '¿Qué le sugerirías o recomendarías a las siguientes generaciones?',
      content: ` - Hablar con gente de medicina que haya ido al viaje antes de ir, porque en las reuniones no te haces mucho la idea de como se vive. <br><br>

     - que vayan al viaje entero y no se preocupen por pelotudeces <br><br>

     - Los destinos están zarpados! Pero esta gente, más aún <br><br>

     - Que vayan con idea de a dónde van, que se permitan descansar y disfrutar a la vez, que se abran a conocer más gente que los amigos con los que van <br><br>

     - Viaje unico. Pero de verdad nunca mas se da un viaje con tanta gente y tanta buena onda. <br><br>

     - Que sean mas compañeros y se abran a hacer nuevas amistades. Creo que se disfruta mucho mas el viaje de esa forma. <br><br>

     - Que lo hagan y se animen a ir a India y Nepal, te vuela la cabeza y te replanteas muchas cosas <br><br>

     - Que les crean que conocen los lugares y a donde van <br><br>

     - Que lo hagan si o si. No tengo comentarios negativos para hacer del viaje. Y que no es taaan barato como se pinta <br><br>


     - Que se preocupen menos por gastarse toda la guita en regalos y disfruten del momento. Que no vayan solo a sacar fotos. <br><br>


     - Ubud, mas dias. Despues el resto todo de 10. <br><br>

     - Que lleven más dinero del recomendado jaja <br><br>

     - Vayan liviano de equipaje y disfruten al mango. El 99% de las cosas son solucionables, vivan el momento y usen poco el cel. Si quieren comprar algo, no esperen al prox destino. Y sobre todo, DIMENSIONEN!! <br><br>

     - Que escuchen todo lo que les dicen en las reuniones, les tiran la posta. Que vayan con la mejor onda y tolerancia, el viaje lo hace uno! Como dijeron en la juntada final en el nido, somos unos privilegiados de poder hacer este viaje y de esta forma

     - Estudiar sobre los lugares <br><br>

     - Que crean tal cual todo lo que se les dice sobre cada experiencia, durante el viaje me acordaba de las reuniones y decía “ellos nos avisaron…”. Pero sobre todo, estar abierto a las experiencias!! Un SÍ a todo. Nunca es tan malo como pensamos, y de todo se sacan cosas buenas <br><br>

     - Viajar liviano!!!
      Meterle onda
      Tratar de ser puntuales para todos! Y los coordinadores ser firmes en esto <br><br>

     - Vayan dispuestos a pasarla bien, a disfrutar, a conocer. Sepan escucharse a uno mismo cuando es tiempo de descansar y cuando es tiempo de seguir. No discutan al pedo y menos con malas intenciones, eso solo genera fricciones innecesarias en momentos donde todos están cansados e irritables. En esos momentos es donde más buena onda hay que ponerle y van a ver que también se disfruta. Siempre que pregunten algo háganlo con buenas intenciones y las mejores de las ondas. Hay gente trabajando hace mucho para que nosotros podamos tener una de las mejores experiencias de la vida y encima vivirla con ellos, no pierdan eso de vista. <br><br>

     - Que si pueden economicamente, hagan el viaje completo, son destinos muy diferentes y todos tienen su magia propia. <br><br>

     - Que no se les ocurra ir a asia sin agencia. <br><br>

     - No se lo pierdan y vayan confiando que va a salir todo bien. <br><br>

     - QUE HAGAN TODO, de principio a fin.
      Todos los destinos te dejan algo nuevo ya sea una enseñanza, un amigo, una anécdota, una experiencia, un aprendizaje.
      En todos vivis, sentís cosas distintas, que valen la pena presenciarlas TODAS.
      No vale robarse destinos <br><br>


     - SEAN CONSCIENTES AL ALQUILAR UNA UNA MOTO EN VIETNAM !!!!!!! <br><br>

     - Ir sin expectativas que se disfruta mucho mas. Abrir la cabeza a las culturas extrañas y entender que hay respetar, observar y aprender de lo que se presente y pueda. <br><br>
      Escuchen a las recomendaciones, suelen ser muy buenas, sobre todo con la comida rica. Investiguen ustedes que pueden encontrar en cada lugar segun sus gustos que el viaje se hace mucho mas completo y es dificil que la empresa contemple todo. <br><br>

     - Vayan dispuestos a pasarla bien, a disfrutar, a conocer. Sepan escucharse a uno mismo cuando es tiempo de descansar y cuando es tiempo de seguir. No discutan al pedo y menos con malas intenciones, eso solo genera fricciones innecesarias en momentos donde todos están cansados e irritables. En esos momentos es donde más buena onda hay que ponerle y van a ver que también se disfruta. Siempre que pregunten algo háganlo con buenas intenciones y las mejores de las ondas. Hay gente trabajando hace mucho para que nosotros podamos tener una de las mejores experiencias de la vida y encima vivirla con ellos, no pierdan eso de vista. <br><br>

`,
    },
    {
      title: 'Espacio libre para comentarios sobre la filosofía del viaje.',
      content: `- Me pareció ideal la manera en que se manejaban las cosas. En India fue donde me sentí más acompañada pero porque el destino lo ameritaba. El resto de los destinos con las libertades de hacer lo que quisiéramos, pero con las cosas “claves” en cada destino organizadas, fue espectacular. <br><br>

     - Me parecio que tiene la estructura adecuada, ni tan estructurado ni tan libre <br><br>

     - Por momentos éramos muchos! Ya era cansador esperar a todos
      Tendrían que haberse puesto más firmes desde el día 1, al que no llegó se lo dejo, y todos hubiésemos cumplido mucho más los transportes
      Entiendo que sino los iban a atomizar a ustedes pero los demás nos clavamos esperando mucho
      Agregar hoteles o late check out en ciertos puntos <br><br>

     - Es un viaje que cada uno afronta de forma distinta, hay para la gente que lo lleva por el lado espiritual, cultural o simplemente ir a divertirse y conocer lugares nuevos y lindos. En mi caso fue todo, y las malas experiencias o cosas que no me gustaron también las recuerdo con cariño, porque lo bailado no me lo quita nadie. <br><br>

     - En cuanto a esto creo que fue excelente, un buen balance entre la libertad necesaria para realizar nuestras propias actividades y las que se deberian hacer en grupo. <br><br>

     - Creo que es uno de los encantos del viaje y ayuda mucho a ir en vibra chill y da poder de elección de cada barrita. Lo grande y más importante (transporte-hoteles) en general no fallaron. Hubieron pequeñas cosas como el bus que no paro desde HCM-hanoi que hubiera estado buena que parara en alguna ciudad <br><br>


     - Estuvo excelente, con recomendaciones muy buenas y cada uno podía elegir lo que mas le gustaba. La cantidad de salidas grupales o planes para todo el grupo estuvo super bien distribuida por ciudad <br><br>

     - ME ENCANTOOOOO! Yo tuve la oportunidad de hacer muchos viajes en grupos y también sola y para el tipo de viaje, la edad, el motivo estuvo genial como lo manejaron! Me encanta porque si bien había actividades en grupo grande que estaba re divertidas también estaba la posibilidad de hacer otras en grupos reducidos. Me encanto que fuera todo muy tranqui en el sentido de cero estrés si querías ir ibas si no querías no ibas. Ideal que no fuera estructurado tipo cronograma, creo que para el tipo de viaje que queremos sería un embole eso! <br><br>


     - Antes de viajar tenia pila de miedo por este punto. Ya he hecho otros viajes antes con un grupo de amigos mas reducido y me encanta esa modalidad, improvisar y resolver en el momento situaciones, ir viendo que hacer segun el día y como nos sentimos. Yo pensaba (erroneamente), que este viaje, como era con tantas personas, iba a ser suuuuper estructurado, tipo viaje de 15, que no iba a haber mucha libertad y que iba a ser todo guiado en formato tour.
      Así como fue estuvo genial, permitía desentenderse y relajarse porque lo mas grande estaba solucionado por ustedes (transporte, alojamiento) y por otro lado tener total libertad para hacer en el día lo que tuviéramos ganas, ya fuera sumarse al plan grupal o no. <br><br>

`,
    },
    {
      title: '¿Cambiarías algún destino (país o ciudad) del viaje? (selecciona una de las 5 opciones para cada ciudad)',
      content: `- Mas dias en gili. <br><br>

     - En bali me quedaria siempre en ulwuatu. Ubud lo haria en el dia. <br><br>

     - Me parece que están re bien en general los destinos. Algunos te quedas con la sensación de qué hay más para ver, cómo bali por ejemplo. Lo demás en general está Perfecto <br><br>

     - Si bien me pareció correcto la elección de las ciudades a visitar, tal vez cambiaría eso de estar muy poco tiempo en cada destino y en algunos no se pudo disfrutar al 100%, como por ejemplo Bali, Canggu.
      También hubieron destinos en los que sacaría días y agregaría en otros que a mi parecer son más disfrutables y no los recorrimos en profundidad.
      Es decir creo que optaría por calidad y no cantidad.
      Pero entiendo el tiempo limitado y la necesidad de visitar 7 países en 2 meses. <br><br>

     - Canggu es agotadora la cantidad de horas de viaje para hacer los tours, quizas se podria ir solo a ubud y hacer los tours desde alli y te ahorrarias cambiar de ciudad.
      El Nido es increible pero para las personas que nos quedamos 10 dias se hace un poco monotono, se podria disminuir la cantidad de dias o repartir con otra ciudad cerca. <br><br>

     - Divino todo.
      Lo único feo fue antes de algunos traslados largos no tener cuarto para bañarse por ejemplo Bangkok 40 grados recorrer todos los templos y ese mismo día viajar todos chivados a las Phi Phi.
      Una opcion es no hacer templos el mismo día de irse a Phi Phi <br><br>

     - En Varanasi solo estaría un día, siento que en ese momento uno está medio agotado de india y es una ciudad con mucha intensidad emocional y un día es suficiente.
      En indonesia me hubiera gustado que nos hospedemos en ubud ya que hay muchas más actividades y dado que las playas de bali mismo no eran playas de baño adecuadas.
      El resto hermoso no cambiaría nada. Solo tal vez me hubiera gustado conocer algo más de Camboya, me quedo con gusto a poco. <br><br>

     - Camboya no me mató, me hubiera gustado una noche mas en Bangkok, senti que hay demasiadas cosas por hacer y la noche esta muy buena jaja <br><br>

     - A mi en particular me encantaron las phi phi, así que me quedaría por lo menos una semana más jaja
      Creo que hicieron falta algunos días más en Bangkok y Hoian. Si me dan a elegir un viaje por la misma cantidad de días, sacaría a Siem Reap y Ho Chi Min, y agregaría esos días a Bangkok, Phi Phi, Hoian y Hanoi. Creo que todas las ciudades tuvieron sus particularidades y no me arrepiento de visitar a ninguna. <br><br>

     - Todo me gustó tal cual fue pero quizás sacaría Camboya e iría mas días a Vietnam. Mas que nada por lo que fue el viaje a Camboya. <br><br>

     - La cantidad de días esa bien en casi todos los lugares, me hubiera gustado quizá volver como estaba previsto antes en avión y conocer puket. <br><br>

     - Le agregaría días a pokhara porque hay muchas actividades para hacer, el lugar está increíble y queda muy poco tiempo para disfrutar fuera de las actividades. Por otro lado, le quitaría días a Katmandú porque esta demenos y sin ser por el bungee es un embole. Bangkok se queda súper corto, al menos 1-2 días más debería tener porque los que vienen de Nepal siempre pierden parte del tiempo, y no se llega a recorrer casi nada. En el trekking dan ganas de quedarse por lo menos una noche más y disfrutar del lugar . <br><br>

     - En Bali y Nusa Penida repensaría los paseos, intentando no estar a las corridas para ir a muchos lugares que solo de para sacar fotos
      En Hanoi estaría buena la opción de ir a Sa Pa también los que seguimos el viaje <br><br>

     - Todo bárbaro. Personalmente no me gustó Hanói. <br><br>

     - Evitaria el viaje largo a camboya y haria algo mas en thailandia <br><br>

     - Me hubiese gustado que el viaje no iniciara el día que llegamos a Delhi sino el día siguiente, para dar tiempo a descansar a los que llegamos agotados en el segundo grupo. <br><br>


     - Estaría bueno en Bali si hacemos planes todos los días en Uluwatu, nos quedemos allá o hagamos más planes en Canggu si nos estamos quedando en esa zona. <br><br>


     - Me encantó las phi phi
      No dejaría el nido para el final, hubiese estado bueno terminar en un lugar con mejores instalaciones
      Genial empezar en india con todo
      Bali fue demasiado movido, la gente ya está cansada, las distancias son muy largas y mucho viaje, quizás un día más y hacer las cosas más tranqui
      Genial que nos dejen manejarnos a nuestros tiempos y nadie obligado a nada
      Reencuadrar el rol del médico, tiene que llevar cosas a los paseos
      Las motos de hoi an no van más… y si van no planteadas como paseo grupal, y si se llega a hacer los coordinadores tienen que estar distribuidos a lo largo del camino
      Ho chi minh fue genial!!!! <br><br>

     - Se debería tener considerado con anticipación los late check out en función de los horarios de los vuelos, para que no suceda de no poder bañarnos luego de todo un día de pasear con vuelos y escalas por delante.
      Lo mismo con los check in, sabiendo las horas de los mismos, calcular los transportes para no tener que venir cansados y sucios posterior al viaje y paseos y esperar muchas horas para ingresar a las habitaciones. <br><br>

`,
    },
    {
      title: 'Espacio libre para comentarios generales',
      content: `- La verdad nada más que decirles gracias, por hacer de este viaje una experiencia sin dudas inolvidable. Les agradezco la calidez, la responsabilidad, el buen trato y la habilidad para sacarnos una sonrisa en cualquier momento. Más allá de una empresa, sin tremendo equipo humano. Nos volveremos a encontrar en futuros viajes ❤️ <br><br>

     - Me parecio tremenda la propuesta de la agencia, la tienen clarisima en como hacer viajes de este tipo y con tanta gente. La libertad que te dan en el viaje es impagable. Tambien me gusto la flexibilidad en poder vender o no rifas, y el numero que nosotros queramos; comparando con el viaje de otras carreras hace el proceso pre viaje menos tedioso, y permite a mas gente sumarse, ya sea de la carrera o acompañantes <br>

     - Gracias por tremenda experiencia! Me llevo 2 meses de tremendos recuerdos y vivencias y un grupo de amigos espectacular, solo palabras lindas para ustedes <br><br>


     - Fue un viaje que nos cambió a muchos en varios sentidos. El grupo fue hermoso y la conexión entre todos y con los Cordi fue maravillosa. Siempre y cuando estes bien predispuesto a que puedan surgir inconvenientes como en cualquier viaje, la pasas de 10! <br><br>

     - Muy buen viaje. Se nota que la agencia encara y esta bien organizada. Los destinos estan muy bueno.
      Se vivio una linda experiencia. <br><br>

     - Volvería a repetir el viaje mil veces. Aún tengo pendiente realizar otros viajes con la agencia, realmente me sentí muy cómoda, me encantaron los destinos, los hoteles, las comidas, viajes, experiencias, todo. Los recomendaría sin lugar a dudas. Me divertí muchísimo! No voy a negar qué hubieron desacuerdos o detalles, pero creo que eso no debería opacar los buenos momentos que fueron muchos más! Creo qué hay que aprender a disfrutar sin quejarse tanto. De lo único que me arrepiento es de no haber hecho ninguna extensión jeje
      Gracias chicos!! <br><br>


     - Increible experiencia, seguro no era lo mismo si no estaba planeado de la manera que está hecho. No me estresé por nada durante todo el viaje, y se que problemas no faltaron… Se nota que se mandan a planear viajes así xq SABEN y no juegan con la gente. Increíble como están en cada detalle. Cuando digo que no tengo comentarios negativos es posta, no los tengo. Los felicito! Seguro vuelva a viajar con ustedes y recomendarlos a otras personas. (Las cosas que marque que cambiaría son gustos personales claramente, no es que me parece que están mal planteadas o que arruinaron el viaje). <br><br>

     - Se los dije personalmente y se los vuelvo a repetir. Muchas gracias por todo. Aún no supero el viaje. Fue más de lo que pude desear y me encantó fuerte. Gracias por el laburo de cada uno de los que esta atrás de esta empresa para que las cosas salgan. Fue un viaje inolvidable para mi. Gracias Gracias Gracias❤️. <br><br>

     - Solo palabras de agradecimiento para ustedes! Hicieron que el viaje sea una experiencia única e indescriptible, seguro es una de las cosas más lindas que me ha pasado! Fui feliz, fui muuuy feliz.
      Pasa el tiempo y no hay día que no se me venga a la mente un recuerdo de allá o que me den ganas de mirar las fotos, y así extrañar un poquito más/menos
      Como nos dijo Emi, muchas veces India se valora en el tiempo, así que desde acá he estado apreciándola y hasta ganas de volver ha generado jajajj
      Espero que el reencuentro sea pronto! Los quieroooo dtyt <br><br>

     - Tremendo el viaje! Superó todas mis expectativas y sin duda la mejor parte fue el grupo!! Vamo arriba! <br><br>

     - El viaje me dio mucho. Me dio gente hermosa que voy a ser amigos de por vida, me dio un mayor entendimiento de mi persona y de mi persona en grupo, me hizo crecer mucho en cuanto a autonomía y como ser humano en general. Volví del viaje completamente renovado y siento que soy otra persona en comparación a diciembre del año pasado y esto no fue algo consciente, simplemente pasó y hoy por hoy lo disfruto y estoy eternamente agradecido con toda la gente que me acompaño en este crecimiento personal, mental y espiritual. ❤️ <br><br>

`,
    },
    {
      title: 'Espacio libre sobre el trato humano de la gente de dTyT',
      content: `Unos cracks <br><br>


     - Fueron tan cálidos en todo momento que sentí que los conocía desde hace mucho tiempo! La buena onda con todos nosotros y ganas por trabajar y dar lo mejor se notaba e inspiraba confianza. <br><br>

     - Sigan por favor siendo así que sin dudas es un pilar re importante del viaje que marca la diferencia! <br><br>


     - Unos cracks del primero al ultimo. El q ponga algo distinto es el que tiene mal trato humano. <br><br>
      Con respecto a mi, me parecieron unos capos todos, siempre estuvieron en los momentos que fueron necsarios. El unico punto que yo creo en que podrian mejorar, es en la joda, de vez en cuando se les iban de las manos los aditivos :) jaja. <br><br>


     - Los amooooo <br><br>


     - Tonga un grande. El javo alto ortiva aunque se hace querer. <br><br>


     - Unos crack <br><br>


     - Muy bueno y admirable. <br><br>


     - Excelente todos siempre. Crack Emi por gestionar Sapa!! <br><br>


     - Buena onda todos <br><br>


     - Excelentes personas y coordinadores, siempre al tanto de cada situación y siempre conteniendo en momentos difíciles. Los llevo en el Corazón al Guso, Emi y Mauro <br><br>


      en general muy buen trato y buena onda! <br><br>


     - Súper bien!! Los felicito! Muy contenida siempre y buena onda <br><br>


     - Los chicos con los que viajamos nosotros unos GENIOS, súper buena gente, súper divertidos, súper atentos a todos los detalles y problemas, y siempre siempre con buena onda, aun en los momentos que estamos todos agotados y ellos encima con la responsabilidad de estar ahí para todos nosotros en la forma en que lo precisamos. Lo más lindo de todo es poder decir que compartí un viaje de este calibre con gente que tiene tanta calidad humana y que se integraron y sumaron en todos los aspectos posibles a que fuera un TREMENDO viaje. <br><br>


     - De las mejores cosas del viaje, hermoso haber compartido este viaje con Tonga, gonchi y el Javo, se integraron al grupo como si fueran uno mas de nosotros, transmitiendo la confianza para poder plantear cualquier actitud, sin dudas considero que es el punto mas fuerte de la empresa, espero que el correr de los años no le quite la energia y el humor.

      ABRAZO <br><br>


     - Solo una queja quizá. El killy se quedó corto de implementos médicos (más ondasetron iv) por ej. Pero después todo el team, unos genios. Emi, Mauro y Killy mucha buena onda y disposición así como Guso a pesar de ciertas situaciones pudiste mantener la buena onda. Se valora un montón <br><br>


    - Me encantó en general la dinámica durante el viaje y la relación a la que llegamos con todos los de dtyt- el único comentario es que quizás en algunas (las pocas) situaciones vividas por otros compañeros (previaje o durante el viaje) faltó comunicación sobre temas que pudieran ser conflictivos (pasajes y eventos del viaje). <br><br>


    - unos crackkkkkk <br><br>


    - Tremendas personas siempre dispuestos para todos y buscando siempre soluciones para lo que no salía tan bien <br><br>


     - Unos genios, muy amables y disponibles para todo, ya sean cosas de la estructura del viaje, como cosas mas personales que te permitian vivir toda la experiencia del viaje de forma completa con apoyo y charlas. <br><br>


     - Unos cra. ABZ <br><br>


    - 5 palabras CRACK <br><br>


    - Genios todos! Súper dispuestos a ayudarte en cualquier problema, divertidos y resolutivos!
      Fueron parte indispensable del grupo! <br><br>


     - Todos los de la agencia sin excepción tuvieron un excelente trato desde mi punto de vista. Siempre dispuestos a solucionar nuestras problemáticas y escucharnos. Desentendimientos hay y va a haber siempre, y más teniendo en cuenta la cantidad de gente que éramos. A pesar de esto para mi estuvo muy bien!! <br><br>


     - Me encariñé, altos cracks. <br><br>


     - Si bien nunca sentí maltrato creo que muchas veces se comportaron como un viajero más, prescindiendo de su rol como guías y como responsables de un grupo de tantas personas. En mi concepto si bien están viajando también están trabajando y obvio que podemos llevarnos bien y pasarla bien pero no deja de ser un vínculo cliente- empresa en donde debe primar la profesionalidad y seriedad de la empresa. <br> <br>


     - Cracksss <br><br>


     - Son todos unos cracks, siempre apoyando y estando ahí, acompañan en todo momento, hasta en la joda y eso esta tremendo! Siempre re buena onda y mucha disposición. Tonga vos sacate la gorra, labura menos y disfruta más <br><br>

`,
    },
  ];

  public showVideo = false;
  public mobile = false;

  constructor(private gaService: GAService) {
    window.addEventListener('hashchange', this.offsetAnchor);
  }

  ngOnInit(): void {
    if (window.innerWidth <= 800) {
      this.mobile = true;
    }
    window.scrollTo(window.scrollX, window.scrollY - 150);
    this.gaService.pageView('/', 'Inicio Web');
  }

  showPlayer() {
    this.showVideo = true;
  }

  hidePlayer() {
    this.showVideo = false;
  }

  offsetAnchor() {
    if (location.hash.length !== 0) {
      window.scrollTo(window.scrollX, window.scrollY - 150);
    }
  }
}
