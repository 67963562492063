<div id="main-slide-container" #mainSlide [class.small-header]="smallHeader || smallHeaderAlways" [class.small-header-always]="smallHeaderAlways">
    <!-- <app-header></app-header> -->
    <div id="header-container">
        <div id="logo-container">
            <a href="https://medicina.detoqueytoque.com">
                <div id="dtyt-logo">
                    <img src="/assets/imgs/dtyt-logo.svg">
                </div>
            </a>
        </div>
        <div id="menu-container">
            <div class="hamburger" (click)="openMenu()" *ngIf="!menu">
                <div class="top-bun"></div>
                <div class="meat"></div>
                <div class="bottom-bun"></div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div id="main-text">
        <div class="title" wmAnimate="fadeInRight" speed="fast" aos once>
            HACENOS TUS PREGUNTAS
        </div>
        <div class="sub-title" wmAnimate="fadeInRight" speed="fast" aos once>
            Vamos a estar recibiendo sus preguntas sobre el viaje y subiendo las respuestas en esta página a la brevedad.
        </div>
        <div class="content-section">
            <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                <input placeholder="Hacenos tu pregunta..." type="text" name="preguntaTxt" [(ngModel)]="preguntaTxt">
                <!-- <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt"> -->
            </div>
            <div class="fields-success" *ngIf="successMsg == true">
                Gracias! En breve vamos a subir la respuesta a esta página y al IG (@hacia.asia.med)... Podés escribir otra pregunta si querés. Saludos!
            </div>
            <div class="fields-error" *ngIf="fieldsError != ''">
                {{ fieldsError }}
            </div>
            <div class="contact-content contact-message" *ngIf="!loading && errorMsg">
                <div class="contact-title">
                    Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898" target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
                </div>
            </div>
            <div class="myrecaptcha">
                <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
            </div>
            <div class="button-slide" (click)="send()">
                ENVIAR
            </div>
        </div>
    </div>
    <div class="ver-mas-slide">VER PREGUNTAS<br>Y RESPUESTAS</div>
    <div class="clearfix"></div>
</div>
<div id="small-header-container" #smallHeaderContainer [class.small-header]="smallHeader || smallHeaderAlways" [class.black-white]="pais && !smallHeader" [class.scrolled]="smallHeader">
    <!-- <app-header></app-header> -->
    <div id="header-container">
        <div id="logo-container">
            <a href="https://medicina.detoqueytoque.com">
                <div id="dtyt-logo" *ngIf="(pais && smallHeader) || !pais">
                    <img src="/assets/imgs/dtyt-logo.svg">
                </div>
                <div id="dtyt-logo" *ngIf="pais && !smallHeader">
                    <img src="/assets/imgs/logo-black.svg">
                </div>
            </a>
        </div>
        <div id="menu-container">
            <div class="hamburger" (click)="openMenu()" *ngIf="!menu">
                <div class="top-bun"></div>
                <div class="meat"></div>
                <div class="bottom-bun"></div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
</div>
<div class="navigate-down" [class.small-header]="smallHeader || smallHeaderAlways" *ngIf="!smallHeaderAlways">
    <img src="assets/imgs/arrow-down.svg" alt="">
</div>

<div class="menu-wrapper" *ngIf="menu">
    <div class="menu">
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/inicio.svg" alt="">
            </div>
            <a href="/">
                Inicio
            </a>
        </div>
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/fmed.svg" alt="">
            </div>
            <a href="/#exp-fmed">
                Experiencia con FMED
            </a>
        </div>
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/viaje.svg" alt="">
            </div>
            <a href="/#el-viaje">
                El viaje
            </a>
        </div>
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/rifas.svg" alt="">
            </div>
            <a href="/#rifas">
                Rifas
            </a>
        </div>
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/beneficios.svg" alt="">
            </div>
            <a href="/#alianzas-convenios">
                Alianzas y Convenios
            </a>
        </div>
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/preguntas.svg" alt="">
            </div>
            <a href="/#preguntas">
                Preguntas frecuentes
            </a>
        </div>
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/preguntas.svg" alt="">
            </div>
            <a href="/preguntas">
                Hacenos tus preguntas
            </a>
        </div>
        <div class="menu-item" (click)="closeMenu()">
            <div class="img-icon">
                <img src="/assets/imgs/icons/contacto.svg" alt="">
            </div>
            <a href="/#contacto">
                Contacto / Reuniones
            </a>
        </div>
        <div class="initial-menu">
            <div class="menu-item" (click)="closeMenu()">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/pdf.svg" alt="">
                </div>
                <a href="https://bit.ly/MED2024" target="_blank">
                    Descargar propuesta PDF
                </a>
            </div>
            <div class="menu-item" (click)="closeMenu()">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/ig.svg" alt="">
                </div>
                <a href="https://www.instagram.com/hacia.asia.med" target="_blank">
                    @hacia.asia.med
                </a>
            </div>
            <div class="menu-item" (click)="openAppViaje()">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/appdtyt.svg" alt="">
                </div>
                Probar app de viaje dTyT
            </div>
            <!-- <div class="menu-item" (click)="openAppBeneficios()">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/appbeneficio.svg" alt="">
                </div>
                Probar app de beneficios dTyT
            </div> -->
            <div class="menu-item" (click)="closeMenu()">
                <div class="img-icon">
                    <img src="/assets/imgs/icons/encuestas.svg" alt="">
                </div>
                <a href="https://bit.ly/dTyT_EncMed23" target="_blank">
                    Encuestas de satisfacción de<br>generaciones anteriores
                </a>
            </div>
        </div>
    </div>
    <div class="hamburger clicked" (click)="closeMenu()">
        <div class="top-bun"></div>
        <div class="meat"></div>
        <div class="bottom-bun"></div>
    </div>
</div>

<div class="menu-wrapper init-modal" *ngIf="initModal">
    <div class="dtyt-logo">
        <img src="/assets/imgs/hacia-asia.svg">
    </div>
    <div class="init-modal-text">
        Nos toca expresar la propuesta y la intención del viaje de esta forma virtual... Pero los invitamos a que vengan a la oficina / bar a charlar del viaje, a sacarse dudas, a contarnos sus inquietudes y sus intenciones. Mientras tanto, aquí va la propuesta y diferentes herramientas para intentar hacer que las palabras cobren vida!
    </div>
    <!-- <div class="init-modal-text">
        Les dejamos la información compuesta por diferentes herramientas para que las palabras tengan sentido, para viajar mientras se lee.
    </div> -->
    <div class="menu" *ngIf="!appViaje && !appBeneficios">
        <div class="menu-item">
            <a href="https://bit.ly/MED2024" target="_blank">
                <div class="menu-item-img">
                    <img src="/assets/imgs/homeicon_pdf.png" alt="">
                </div>
                <div class="menu-item-text">
                    DESCARGAR<br>PROPUESTA
                </div>
            </a>
        </div>
        <div class="menu-item" (click)="closeInitModal()">
            <div class="menu-item-img">
                <img src="/assets/imgs/homeicon_web.png" alt="">
            </div>
            <div class="menu-item-text">
                VER WEB<br>DEL VIAJE
            </div>
        </div>
        <div class="menu-item">
            <a href="https://www.instagram.com/hacia.asia.med" target="_blank">
                <div class="menu-item-img">
                    <img src="/assets/imgs/homeicon_ig.png" alt="">
                </div>
                <div class="menu-item-text">
                    @hacia.asia.med
                </div>
            </a>
        </div>
        <div class="menu-item" (click)="openAppViaje()">
            <div class="menu-item-img">
                <img src="/assets/imgs/homeicon_appviaje.png" alt="">
            </div>
            <div class="menu-item-text">
                PROBAR<br>APP DE VIAJE
            </div>
        </div>
        <div class="menu-item" (click)="openAppBeneficios()">
            <div class="menu-item-img">
                <img src="/assets/imgs/homeicon_rifa.png" alt="">
            </div>
            <div class="menu-item-text">
                PROBAR APP<br>DE BENEFICIOS
            </div>
        </div>
        <div class="menu-item">
            <a href="https://bit.ly/dTyT_EncMed23" target="_blank">
                <div class="menu-item-img">
                    <img src="/assets/imgs/homeicon_encuestas.png" alt="">
                </div>
                <div class="menu-item-text">
                    ENCUESTAS DE GENERACIONES ANTERIORES
                </div>
            </a>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="apps-modal apps-modal-viaje" *ngIf="appViaje">
        <div class="apps-modal-left apps-modal-viaje">
            <div class="apps-modal-logo">
                <img src="/assets/imgs/viaje-app.svg" alt="">
            </div>
            <div class="apps-modal-user">
                Usuario: <span>med2024@dtyt.com</span><br> Contraseña: <span>med2024</span><br>
            </div>
            <div class="apps-modal-links">
                <div class="apps-modal-links-text">
                    Probá nuestra apps de viajes
                </div>
                <div class="apps-modal-btns">
                    <div class="btn-apps">
                        <a href="https://apps.apple.com/uy/app/de-toque-y-toque/id1447171270?l=es" target="_blank">
                            <img src="/assets/imgs/apple-btn.png" alt="">
                        </a>
                    </div>
                    <div class="btn-android">
                        <a href="https://play.google.com/store/apps/details?id=dtyt.app&hl=es_UY" target="_blank">
                            <img src="/assets/imgs/android-app.png" alt="">
                        </a>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="hamburger clicked" (click)="closeAppModal()">
                <div class="top-bun"></div>
                <div class="meat"></div>
                <div class="bottom-bun"></div>
            </div>
        </div>
    </div>
    <div class="apps-modal apps-modal-viaje" *ngIf="appBeneficios">
        <div class="apps-modal-left apps-modal-viaje">
            <div class="apps-modal-logo">
                <img src="/assets/imgs/beneficio-app.svg" alt="">
            </div>
            <div class="apps-modal-main-text">
                Creá un usuario y mirá todos los beneficios que tenemos
            </div>
            <div class="apps-modal-links">
                <div class="apps-modal-links-text">
                    Probá nuestra apps de beneficios
                </div>
                <div class="apps-modal-btns">
                    <div class="btn-apps">
                        <a href="https://apps.apple.com/uy/app/dtyt-beneficios/id1499366980?l=es" target="_blank">
                            <img src="/assets/imgs/apple-btn.png" alt="">
                        </a>
                    </div>
                    <div class="btn-android">
                        <a href="https://play.google.com/store/apps/details?id=com.houlak.dtyt&hl=es_419" target="_blank">
                            <img src="/assets/imgs/android-app.png" alt="">
                        </a>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="hamburger clicked" (click)="closeAppModal()">
                <div class="top-bun"></div>
                <div class="meat"></div>
                <div class="bottom-bun"></div>
            </div>
        </div>
    </div>
    <div class="dtyt-logo-white">
        <a href="https://detoqueytoque.com">
            <img src="/assets/imgs/dtyt-logo.svg">
        </a>
    </div>
</div>