import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.scss'],
})
export class ChartPieComponent implements OnInit {
  chartOptions = {
    responsive: true,
  };
  @Input() chartLabels = ['Valor 1', 'Valor 2'];
  @Input() chartData = [500, 150];
  chartColors = [
    {
      backgroundColor: ['#ff5c2d', '#eee', '#2e65ff38'],
      borderColor: ['#ff5c2d', '#eee', '#2e65ff38'],
    },
  ];
  chartLegend = true;
  chartPlugins = [];

  constructor() {}

  ngOnInit(): void {}
}
