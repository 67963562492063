<div class="swiper-box" wmAnimate="fadeIn" #swiperAnimationIn aos once speed="faster" [class.dark]="theme == 'dark'" [class.light]="theme == 'light'">
    <div class="swiper-title">
        {{ title }}
    </div>
    <swiper [config]="config">
        <div class="swiper-slide small-swiper-slide" *ngFor="let item of listadoItems" wmAnimate="pulse" speed="superFaster" aos once>
            <div class="slide-image">
                <app-chart-pie [chartLabels]="item.chartLabels" [chartData]="item.chartData"></app-chart-pie>
            </div>
            <div class="small-slide-title">
                {{ item.title }}
            </div>
            <div class="small-slide-description">
                {{ item.description }}
            </div>
        </div>
    </swiper>
    <div class="swiper-chart-prev swiper-custom-arrow"></div>
    <div class="swiper-chart-next swiper-custom-arrow"></div>
    <div class="clearfix"></div>
    <div class="swiper-custom-pagination"></div>
</div>