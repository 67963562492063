import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  public maxDias: number;
  public maxCiudades: number;
  public minPaquete: any;
  public maxPaquete: any;

  paquetes = [
    {
      paquete_id: 1,
      ciudades: [
        {
          ciudad_name: 'Ciudad 1',
          dias: 3,
        },
        {
          ciudad_name: 'Ciudad 2',
          dias: 4,
        },
      ],
    },
    {
      paquete_id: 2,
      ciudades: [
        {
          ciudad_name: 'Ciudad 1',
          dias: 3,
        },
        {
          ciudad_name: 'Ciudad 2',
          dias: 4,
        },
        {
          ciudad_name: 'Ciudad 3',
          dias: 3,
        },
      ],
    },
    {
      paquete_id: 3,
      ciudades: [
        {
          ciudad_name: 'Ciudad 2',
          dias: 4,
        }
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.setVars();
  }

  setVars() {
    this.maxDias = 0;
    this.maxCiudades = 0;

    let lMinCiudades = 0;

    this.paquetes.forEach((paquete) => {
      let amountDias: number = 0;
      let amountCiudades = 0;

      paquete.ciudades.forEach((ciudad) => {
        amountDias += ciudad.dias;
        amountCiudades++;
      });

      if (amountDias > this.maxDias) {
        this.maxDias = amountDias;
      }

      if (amountCiudades < lMinCiudades || lMinCiudades == 0) {
        this.minPaquete = paquete;
      }

      if (amountCiudades > this.maxCiudades) {
        this.maxCiudades = amountCiudades;
        this.maxPaquete = paquete;
      }
    });
  }
}
